@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
    height: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--montserrat-font);
    font-style: normal;
}

p, article {
    font-family: var(--roboto-font);
    font-style: normal;
    line-height: 1.6;
}

a:hover {
    color: theme(colors.red-brand);
    text-decoration: underline;
}

#map {
    position: revert !important;
    width: 100%;
    max-height: 500px;
}

@layer components {
    .studio a {
        @apply hover:text-red-brand-dark;
    }

    .hero-title {
        @apply font-bold text-3xl py-12 md:text-4xl lg:text-5xl xl:text-6xl text-center text-white leading-7 md:leading-10;
    }

    .hero-text {
        @apply md:mt-8 mt-12 lg:w-10/12 font-normal text-center text-white text-xl;
    }

    .btn {
        @apply bg-red-brand border border-red-brand px-4 py-2 uppercase text-white transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 sm:px-8 sm:py-3 lg:text-xl lg:font-bold hover:bg-red-brand-dark hover:border-red-brand-dark;
    }

    .btn-dark {
        @apply bg-brand-800 border border-brand-800 px-4 py-2 uppercase text-white transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 sm:px-8 sm:py-3 lg:text-xl lg:font-bold hover:bg-red-brand-dark hover:border-red-brand-dark;
    }

    .input {
        @apply w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out;
    }

    .select {
        @apply appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none;
    }

    .text-section {
        @apply text-off-white md:mt-5 mt-10 lg:w-10/12 font-normal text-base;
    }

    .link {
        @apply text-white underline;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.auth-widget .button > .loader,
.account .button > .loader {
    width: 17px;
    animation: spin 1s linear infinite;
    filter: invert(1);
}

/* Account */

.account {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
